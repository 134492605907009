import React from "react"
import { Link } from "gatsby"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

export default () => (
  <Layout>
    <SEO title="About us" meta={[]} />
    <section>
      <p>
        Endeavour Screens is a Gold Coast family owned business, with over 30 years of experience, specializing in security doors and screens. We are
        an award winning authorized Crimsafe Manufacturer, building all Crimsafe products in our Ashmore factory. For over 30 years we have proudly
        delivered the highest quality, greatest value & the very best service for security screens and doors. Call us today for a free measure and
        quote or to use our quote request form click <Link to="/contact-us">here</Link>
      </p>
    </section>
  </Layout>
)
